import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Spinner } from '@/components/Spinner';
import { CONSTANTS } from '@/constants/constants';
import { AreaContext } from '@/contexts/AreaContext';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  Divider,
  Icon,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import Approve from '../DataQuality/components/Approve';
import { TPartApprovalStatus } from '../Frame/types';
import { TPart } from './types';

import PartDetailAlternativeSources from './components/PartDetailAlternativeSources';
import PartDetailApplications from './components/PartDetailApplications';
import PartDetailCastingVendors from './components/PartDetailCastingVendors';
import PartDetailDescription from './components/PartDetailDescription';
import PartDetailOwner from './components/PartDetailOwner';
import PartDetailPredecessors from './components/PartDetailPredecessors';
import PartDetailReferences from './components/PartDetailReferences';
import PartDetailSuccessors from './components/PartDetailSuccessors';

import useGroups from '@/hooks/useGroups';
import PartDetailTags from './components/PartDetailTags';
import PartApplicationMatrixTable from './components/tables/PartApplicationMatrix';
import PartMaterialTable from './components/tables/PartMaterialTable';
import PartScrapRatesTable from './components/tables/PartScrapRates';
import PartSetCountTable from './components/tables/PartSetCountTable';
import PartStatusTable from './components/tables/PartStatusTable';

const PartDetail = () => {
  const { area, setArea } = useContext(AreaContext);

  useEffect(() => {
    if (area !== 'parts') setArea('parts');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const groups = useGroups();
  const { part_id } = useParams();
  const [searchParams] = useSearchParams();
  const params = searchParams.get('status');

  const status = params as TPartApprovalStatus;
  const [forceReload, setForceReload] = useState(0);

  //If status is not a required one change it to empty
  //if (status !== 'PENDING' && status !== 'POSTPONED') status = '';

  const canEdit = groups
    ? groups.includes(import.meta.env.VITE_AZURE_ADMIN_GROUP)
    : false;

  const { isLoading, error, data, makeApiCall } = useApiCall<TPart>();

  useEffect(() => {
    if (error && status === 'PENDING') {
      setForceReload(1);
      navigate('/parts/detail/' + part_id, { replace: true });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/part/' +
        part_id +
        (status && forceReload === 0 ? '?status=' + status : ''),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part_id, forceReload]);

  if (isLoading) {
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner borderSize={8} size={64} color="#CCCCCC" />
      </div>
    );
  }

  /* if (error) {
    return <div className="contentContainer">Error: {error.message}</div>;
  } */

  if (data) {
    if (!data.title)
      return <div className="contentContainer">Part cannot be displayed</div>;
    document.title = CONSTANTS.en.TITLE + ' - ' + data.title.bom;
    return (
      <>
        <div className="sticky">
          <Stack direction="row" gap={5} verticalAlign="center">
            <Link to="/parts">Parts</Link>
            <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />
          </Stack>
          <div style={{ float: 'right' }}>
            {status === 'PENDING' || status === 'POSTPONED' ? (
              <Approve
                id={data.id}
                bom={data.title.bom}
                status={status as TPartApprovalStatus}
                view="Detail"
              />
            ) : (
              <Stack direction="row" gap={10}>
                <Button
                  type="primary"
                  onClick={() => navigate('/parts/compare/' + part_id)}
                >
                  Compare
                </Button>
                <Button
                  type="primary"
                  onClick={() => navigate('/parts/part_development/' + part_id)}
                >
                  Part Development
                </Button>
              </Stack>
            )}
          </div>
          <Typography tag="h3">
            {`${data.title.bom} (${data.title.frame_family} - ${data.title.part_type})`}
          </Typography>

          <div style={{ paddingTop: 20 }}>
            <Divider orientation="horizontal" padding={0} />
          </div>
        </div>

        <div className="contentContainer">
          <div style={{ paddingTop: 20 }}></div>
          <Typography tag="textdefault">
            <PartDetailDescription
              value={data.description}
              bom={data.title.bom}
              editable={canEdit}
            />
          </Typography>

          {(data.tags && data.tags.length > 0) || canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Tags</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailTags
                  value={data.tags}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null}
          {/* 
//Deprecated
          {(data.earmarks && data.earmarks.length > 0) || canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Earmarks</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailEarmarks
                  value={data.earmarks}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null}
 */}
          {data.part_owners &&
            data.part_owners.length > 0 &&
            data.part_owners[0].owners.length > 0 && (
              <div style={{ paddingTop: 20 }}>
                <Typography tag="textlarge">Owner(s)</Typography>
                <Divider orientation="horizontal" padding={5} />
                <PartDetailOwner value={data.part_owners} />
              </div>
            )}

          {(data.status || canEdit) && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Status</Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartStatusTable
                status={data.status}
                bom={data.title.bom}
                editable={canEdit}
              />
            </div>
          )}

          {data.set_part_count && data.set_part_count.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Set / Part Count</Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartSetCountTable setcount={data.set_part_count} />
            </div>
          )}

          {data.material && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Material</Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartMaterialTable material={data.material} />
            </div>
          )}

          {data.casting_vendors && data.casting_vendors.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Casting Vendor(s)</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailCastingVendors value={data.casting_vendors} />
              </Typography>
            </div>
          )}

          {/* 
//Deprecated
          {(data.design_features && data.design_features.length > 0) ||
          canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Design Features</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailDesignFeatures
                  value={data.design_features}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null} */}

          {data.applications && data.applications.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Applications</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailApplications value={data.applications} />
              </Typography>
            </div>
          )}

          {/* {(data.part_lifes && data.part_lifes.length > 0) || canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Part Lifes (new unit)</Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartLifeTable
                partlife={data.part_lifes}
                bom={data.title.bom}
                editable={canEdit}
              />
            </div>
          ) : null} */}

          {/*   {(data.maintenance_concepts &&
            data.maintenance_concepts.length > 0) ||
          canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textdefault">
                Maintenance Concepts (new unit)
              </Typography>

              <Typography tag="textsmall">
                <PartDetailMaintenance
                  value={data.maintenance_concepts}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null} */}

          {data.scrap_rates && data.scrap_rates.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Scrap Rates (service)</Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartScrapRatesTable scrapRates={data.scrap_rates} />
            </div>
          )}

          {data.application_matrix && data.application_matrix.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">
                Application Matrix (service)
              </Typography>
              <Divider orientation="horizontal" padding={5} />
              <PartApplicationMatrixTable
                applicationmatrix={data.application_matrix}
                size="small"
              />
            </div>
          )}

          {(data.predecessors && data.predecessors.length > 0) || canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Predecessor</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailPredecessors
                  value={data.predecessors}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null}

          {data.successors && data.successors.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Successor</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailSuccessors value={data.successors} />
              </Typography>
            </div>
          )}

          {data.alternative_sources && data.alternative_sources.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Alternative Source(s)</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailAlternativeSources
                  value={data.alternative_sources}
                />
              </Typography>
            </div>
          )}

          {/*   {data.design_reviews && data.design_reviews.length > 0 && (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">Design Reviews</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailDesignReviews value={data.design_reviews} />
              </Typography>
            </div>
          )} */}

          {(data.references && data.references.length > 0) || canEdit ? (
            <div style={{ paddingTop: 20 }}>
              <Typography tag="textlarge">References</Typography>
              <Divider orientation="horizontal" padding={5} />

              <Typography tag="textdefault">
                <PartDetailReferences
                  value={data.references}
                  bom={data.title.bom}
                  editable={canEdit}
                />
              </Typography>
            </div>
          ) : null}
        </div>

        {/*  <pre>{JSON.stringify(data, null, 2)}</pre>; */}
      </>
    );
  }
  if (error) {
    return <div className="contentContainer">Error: {error.message}</div>;
  }
  return <div className="contentContainer">Not available</div>;
};

export default PartDetail;
