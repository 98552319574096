import { TTagCategory } from '@/pages/Part/types';
import { createContext } from 'react';

export const CategoryContext = createContext<{
  tagCategories: TTagCategory[];
  setTagCategories: React.Dispatch<React.SetStateAction<TTagCategory[]>>;
}>({
  tagCategories: [],
  setTagCategories: () => {},
});
