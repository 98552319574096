import { createContext } from 'react';
import { TSearchArea, TSearchParameters } from './AreaProvider';
import { initialSearchParameters } from './defaultObjects';

export const AreaContext = createContext<{
  area: string;
  setArea: React.Dispatch<React.SetStateAction<string>>;
  searchArea: TSearchArea;
  setSearchArea: React.Dispatch<React.SetStateAction<TSearchArea>>;
  searchParameters: TSearchParameters;
  setSearchParameters: React.Dispatch<React.SetStateAction<TSearchParameters>>;
  personalParameters: TSearchParameters;
  setPersonalParameters: React.Dispatch<
    React.SetStateAction<TSearchParameters>
  >;
}>({
  area: 'home',
  setArea: () => {},
  searchArea: 'attributes',
  setSearchArea: () => {},
  searchParameters: { ...initialSearchParameters },
  setSearchParameters: () => {},
  personalParameters: { ...initialSearchParameters },
  setPersonalParameters: () => {},
});
