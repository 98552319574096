import { ReactNode, useState } from 'react';
import { AreaContext } from './AreaContext';
import { initialSearchParameters } from './defaultObjects';

export type TSearchArea = 'attributes' | 'bom';

export type TSearchParameters = {
  bom_list: string;
  text: string;
  bom: string;
  part_type: string;
  frame_family: string;
  application: string;
  pdp_status: string;
  sap_status: string;
  engineering_status: string;
  base_material: string;
  internal_coating: string;
  external_coating: string;
  thermal_barrier: string;
  top_coat: string;
  earmark: string;
  casting_vendor: string;
  source: string;
  assembly_cluster: string;
  life_cluster: string;
  design_feature: string;
  part_owners: string;
  tags: string;
};

export type TPersonalParameters = {
  [K in keyof TSearchParameters]: string extends TSearchParameters[K]
    ? string[]
    : TSearchParameters[K];
};

type TSearchParametersWithEndpoint = {
  endpoint: string;
  label: string;
  has_id: boolean;
};

export type TSearchParametersWithEndpointLabel = {
  [K in keyof Omit<
    TSearchParameters,
    'bom_list' | 'text' | 'bom'
  >]: TSearchParametersWithEndpoint;
};

export type TOnlySearchItems = Omit<
  TSearchParametersWithEndpointLabel,
  'bom_list' | 'text' | 'bom'
>;

type TAreaProvider = {
  children: ReactNode;
};

export const AreaProvider = ({ children }: TAreaProvider) => {
  const [area, setArea] = useState<string>('home');
  const [searchArea, setSearchArea] = useState<TSearchArea>('attributes');
  const [searchParameters, setSearchParameters] = useState(
    initialSearchParameters,
  );
  const [personalParameters, setPersonalParameters] = useState(
    initialSearchParameters,
  );

  return (
    <AreaContext.Provider
      value={{
        area,
        setArea,
        searchArea,
        setSearchArea,
        searchParameters,
        setSearchParameters,
        personalParameters,
        setPersonalParameters,
      }}
    >
      {children}
    </AreaContext.Provider>
  );
};
