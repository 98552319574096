/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BOM } from '@/components/BOM';
import { Spinner } from '@/components/Spinner';
import { AreaContext } from '@/contexts/AreaContext';
import { CategoryContext } from '@/contexts/TagCategories';
import useApiCall from '@/hooks/useApiCall';
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Icon,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TPart, TPartTagDetail } from '../Part/types';
import styles from './PartPedigree.module.scss';

type TPartPedigree = {
  bom: string;
  frame_family: string;
  part_type: string;
  tags: TPartTagDetail[];
  successors: TPartPedigree[];
  predecessors: TPartPedigree[];
};

type TNodePre = {
  item: InvertedPedigree;
};
type TNode = {
  item: TPartPedigree;
};
type InvertedPedigree = {
  bom: string;
  tags: TPartTagDetail[];
  children: InvertedPedigree[];
};

/* 
function invertNestedPedigree(data: TPartPedigree[]) {
  const inverted: InvertedPedigree[] = [];
  const stack: { data: TPartPedigree; children: InvertedPedigree[] }[] = [];

  stack.push(...data.map((item) => ({ data: item, children: [] })));

  while (stack.length) {
    const { data, children } = stack.pop()!;

    if (!data.predecessors.length) {
      inverted.push({ bom: data.bom, children: [] });
    } else {
      for (const predecessor of data.predecessors) {
        stack.push({ bom: predecessor.bom, children: [...children, data.bom] });
      }
    }
  }

  return inverted;
} */

function invertNestedPedigree(data: TPartPedigree[]) {
  const inverted: InvertedPedigree[] = [];
  const stack: { data: TPartPedigree; children: InvertedPedigree[] }[] = [];

  stack.push(...data.map((item) => ({ data: item, children: [] })));

  while (stack.length) {
    const { data, children } = stack.pop()!;

    if (!data.predecessors.length) {
      inverted.push({ bom: data.bom, tags: data.tags, children });
    } else {
      for (const predecessor of data.predecessors) {
        stack.push({
          data: predecessor,
          children: [
            ...children,
            { bom: data.bom, tags: data.tags, children: [] },
          ],
        });
      }
    }
  }

  return inverted;
}

const PartPedigree = () => {
  const { part_id } = useParams();
  const { isLoading, error, data, makeApiCall } = useApiCall<TPartPedigree>();
  const { tagCategories } = useContext(CategoryContext);
  const { area, setArea } = useContext(AreaContext);

  type TTagCategories = {
    [key: number]: boolean;
  };

  const initializeTagCategories = (
    initialState: boolean = true,
  ): TTagCategories => {
    return tagCategories.reduce((obj, key) => {
      obj[key.id] = initialState;
      return obj;
    }, {} as TTagCategories);
  };

  const areAllKeys = (obj: TTagCategories, status: boolean): boolean => {
    return Object.values(obj).every((value) => value === status);
  };
  const getSelectedFromLocalStorage = () => {
    const item = localStorage.getItem('partdevelopment-selectedtags');
    if (item) {
      return JSON.parse(item) as TTagCategories;
    }
    return initializeTagCategories(true);
  };

  const [selectedTagCategories, setSelectedTagCategories] = useState(
    getSelectedFromLocalStorage,
  );

  const navigate = useNavigate();

  const handleBOM = (value: TPart) => {
    navigate('/parts/part_development/' + value.title.bom);
  };

  const handleChangeTag = (cat: number) => {
    setSelectedTagCategories((prev) => ({
      ...prev,
      [cat]: !prev[cat],
    }));
  };

  useEffect(() => {
    if (tagCategories.length > 0) {
      //localstorage key is not set

      setSelectedTagCategories(getSelectedFromLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagCategories]);

  useEffect(() => {
    const item = localStorage.getItem('partdevelopment-selectedtags');
    if (item && Object.keys(selectedTagCategories).length > 0) {
      localStorage.setItem(
        'partdevelopment-selectedtags',
        JSON.stringify(selectedTagCategories),
      );
    } else {
      localStorage.setItem(
        'partdevelopment-selectedtags',
        JSON.stringify(initializeTagCategories(true)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTagCategories]);

  useEffect(() => {
    if (part_id) {
      if (area !== 'parts') setArea('parts');

      makeApiCall({
        method: 'GET',
        url: import.meta.env.VITE_APP_API_URL + '/pedigree/' + part_id,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part_id]);

  const Node = ({ item }: TNode) => {
    return (
      <>
        <li>
          {/* {item.predecessors.length > 0 && (
            <ul>
              {item.predecessors.map((predecessor, index) => (
                <Node key={index} item={predecessor} />
              ))}
            </ul>
          )}{' '} */}

          <div
            className={classNames(
              styles.item,
              item.bom === part_id && styles.selectedItem,
            )}
            onClick={() => navigate('/parts/detail/' + item.bom)}
          >
            <div>{item.bom}</div>
            <Typography tag="textsmall">
              {item.tags.map((el: TPartTagDetail, idx: number) => {
                if (selectedTagCategories[el.category_id]) {
                  return <div key={idx}>{el.tag}</div>;
                } else {
                  return null;
                }
              })}
            </Typography>
          </div>
          {item.successors.length > 0 && (
            <ul>
              {item.successors.map((successor, index) => (
                <Node key={index} item={successor} />
              ))}
            </ul>
          )}
        </li>
      </>
    );
  };

  const NodePre = ({ item }: TNodePre) => {
    return (
      <>
        <li>
          {/* {item.predecessors.length > 0 && (
            <ul>
              {item.predecessors.map((predecessor, index) => (
                <Node key={index} item={predecessor} />
              ))}
            </ul>
          )}{' '} */}
          <div
            className={styles.item}
            onClick={() => navigate('/parts/detail/' + item.bom)}
          >
            <div>{item.bom}</div>
            <Typography tag="textsmall">
              {item.tags.map((el: TPartTagDetail, idx: number) => {
                if (selectedTagCategories[el.category_id]) {
                  return <div key={idx}>{el.tag}</div>;
                } else {
                  return null;
                }
              })}
            </Typography>
          </div>
          {item.children.length > 0 && (
            <ul>
              {item.children.map((child, index) => (
                <NodePre key={index} item={child} />
              ))}
            </ul>
          )}
        </li>
      </>
    );
  };

  if (isLoading) {
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner borderSize={8} size={64} color="#CCCCCC" />
      </div>
    );
  }

  if (error) {
    return <div className="contentContainer">Error: {error.message}</div>;
  }

  if (data || !part_id) {
    let inverted;

    if (data) inverted = invertNestedPedigree(data.predecessors);

    return (
      <div className="contentContainer">
        <div /* className="sticky" */>
          <Stack direction="row" gap={5} verticalAlign="center">
            <Link to="/parts">Parts</Link>
            <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />

            {part_id ? (
              <>
                <Link to={'/parts/detail/' + part_id}>{part_id}</Link>
                <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />
              </>
            ) : null}
          </Stack>

          <Stack direction="row" distribute="space-between">
            <Typography tag="h3">
              Part Development
              {data && data.bom
                ? ` - ${data?.bom} (${data?.frame_family})`
                : null}
              {data && data.part_type ? ` - (${data?.part_type})` : null}
            </Typography>
            <BOM cb={handleBOM} />
          </Stack>

          <Divider orientation="horizontal" padding={20} />
        </div>

        {part_id && inverted && data ? (
          <>
            {/*   <DragAndDrop> */}
            <div className={styles.filters}>
              <Accordion
                title="Tag Groups"
                hasBorder
                hasDivider
                typographyTag="textsmall_strong"
                padding={10}
              >
                <div
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderBottom: 'solid 1px #dadada',
                    textAlign: 'center',
                  }}
                >
                  <Stack direction="row" inline>
                    <Button
                      disabled={areAllKeys(selectedTagCategories, true)}
                      type="link"
                      allCaps={false}
                      onClick={() =>
                        setSelectedTagCategories(initializeTagCategories(true))
                      }
                    >
                      Select All
                    </Button>
                    <Divider orientation="vertical" padding={10} />
                    <Button
                      disabled={areAllKeys(selectedTagCategories, false)}
                      allCaps={false}
                      type="link"
                      onClick={() =>
                        setSelectedTagCategories(initializeTagCategories(false))
                      }
                    >
                      Deselect All
                    </Button>
                  </Stack>
                </div>

                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <Typography tag="textsmall">
                    {tagCategories.map((cat, index) => {
                      if (cat.category === '') return;
                      return (
                        <div
                          key={'el' + cat.id + '_' + index}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Checkbox
                            id={'Checkbox_' + cat.id}
                            isChecked={selectedTagCategories[cat.id]}
                            label={cat.category}
                            onChange={() => handleChangeTag(cat.id)}
                          />
                        </div>
                      );
                    })}
                  </Typography>
                </div>
              </Accordion>
            </div>
            {/*      </DragAndDrop> */}
            <div className={styles.orgChartContainer}>
              <div className={styles.orgChart}>
                {inverted.length > 0 ? (
                  <ul>
                    {inverted.map((item) => (
                      <NodePre key={item.bom} item={item} />
                    ))}
                  </ul>
                ) : null}
                <ul>
                  <Node item={data} />
                </ul>
              </div>
            </div>
          </>
        ) : (
          'Please select a BOM'
        )}
      </div>
    );
  }

  return <div className="contentContainer">Not available</div>;
};

export default PartPedigree;
