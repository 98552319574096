import { Spinner } from '@/components/Spinner';
import { searchParametersWithEndpointLabel } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  SelectV2,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { OptionGroup } from '@data-products-and-ai/react-components/lib/components/Forms/SelectV2/SelectV2';
import { useContext, useEffect, useState } from 'react';
import { TPart, TPartTag, TPartTagDetail } from '../types';
import styles from './PartDetail.module.scss';
import { CategoryContext } from '@/contexts/TagCategories';
type TPartDetailItem = {
  value: TPart['tags'];
  bom: string;
  editable: boolean;
};
const PartDetailTags = ({ value, bom, editable = true }: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState<TPartTagDetail[]>(value);
  const { tagCategories } = useContext(CategoryContext);

  const { data, makeApiCall } = useApiCall<{
    [key: string]: TPartTag[];
  }>();

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/' +
        searchParametersWithEndpointLabel.tags.endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dynamicValue) return;

  type TTagList = {
    id?: number;
    tag_id?: number;
    setDynamicValue?: React.Dispatch<React.SetStateAction<TPartTagDetail[]>>;
    setCurrentEdits?: React.Dispatch<React.SetStateAction<number[]>>;
  };
  type TTagUpdate = TTagList & {
    tag: string;
    category: TPartTagDetail['category'];
  };
  type TTagDelete = {
    id: number;
  };

  const tagUpdate = (props: TTagUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL +
            `/part/${bom}/` +
            searchParametersWithEndpointLabel.tags.endpoint,
          payload: { id: props.id, tag_id: props.tag_id },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("ERROR: Tag couldn't be updated");
          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === props.id
              ? {
                  ...item,
                  tag_id: props.tag_id as number,
                  tag: props.tag,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Insert = (props: Partial<TTagUpdate>) => {
    const update = async () => {
      try {
        console.log({
          tag_id: props.tag_id ? props.tag_id.toString() : '',
        });
        return await makeApiCall({
          method: 'POST',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/tags`,
          payload: {
            tag_id: props.tag_id ? props.tag_id.toString() : '',
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== 0),
        );

        if (result.status && result.status !== 200) {
          //error
          setDynamicValue((prev) => prev.filter((item) => item.id !== 0));

          alert("ERROR: Tag couldn't be inserted");
          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === 0
              ? {
                  ...item,
                  id: result.ID,
                  tag_id: props.tag_id ? props.tag_id : 0,
                  tag: props.tag as string,
                  category: props.category as TPartTagDetail['category'],
                }
              : item,
          ),
        );
      })
      .catch(() => {
        setDynamicValue((prev) => prev.filter((item) => item.id !== 0));
        alert('Something went wrong');
      });
  };

  const tagDelete = (props: TTagDelete) => {
    if (confirm('Are you sure you want to delete this tag?') === true) {
      const ApiCall = async () => {
        try {
          return await makeApiCall({
            method: 'DELETE',
            useAsFetch: true,
            url:
              import.meta.env.VITE_APP_API_URL +
              `/part/${bom}/` +
              searchParametersWithEndpointLabel.tags.endpoint,
            payload: { record_id: props.id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.error('Error occurred:', error);

          throw error;
        }
      };

      ApiCall()
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("ERROR: Tag couldn't be deleted");
            return;
          }

          setDynamicValue((prevState) =>
            prevState.filter((item) => item.id !== props.id),
          );
        })
        .catch(() => {
          alert('Something went wrong');
        });
    }
  };

  const TagList = (props: TTagList) => {
    const [publish, setPublish] = useState(false);
    if (!data) return;

    if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

    const Cancel = () => {
      if (props.setCurrentEdits) {
        props.setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );
      }

      //New row
      if (props.id === 0 && props.setDynamicValue) {
        props.setDynamicValue((prev) =>
          prev.filter((item) => item.id !== props.id),
        );
      }
    };

    const groupedByCategory = data.tags.reduce(
      (acc: OptionGroup[], item: TPartTag) => {
        const { category, tag, id } = item;

        // Check if the category already exists in the accumulator
        let categoryGroup = acc.find((cat) => cat.label === category);

        // If the category doesn't exist, create it and add to the accumulator
        if (!categoryGroup) {
          categoryGroup = { label: category, options: [] };
          acc.push(categoryGroup);
        }

        // Add the current item as an option to the appropriate category

        if (category !== '' && !dynamicValue.some((item) => item.tag_id === id))
          categoryGroup.options.push({
            label: tag,
            value: id.toString(),
            category: category,
          });

        return acc;
      },
      [],
    );

    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '400px 100px',
            gap: 10,
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <SelectV2
            noOptionsMessage="No tag found"
            placeholder="Tags"
            hideIndicator
            size="small"
            width="100%"
            closeMenuOnSelect={true}
            options={groupedByCategory}
            onChange={(selectOption) => {
              if (!Array.isArray(selectOption)) {
                setPublish(true);
                setCurrentEdits((prevNumbers) =>
                  prevNumbers.filter((number) => number !== props.id),
                );

                setPublish(true);

                if (props.id === 0) {
                  Insert({
                    id: props.id,
                    tag_id: parseInt(selectOption.value),
                    tag: selectOption.label,
                    category:
                      selectOption.category as TPartTagDetail['category'],
                  });
                } else {
                  tagUpdate({
                    id: props.id,
                    tag_id: parseInt(selectOption.value),
                    tag: selectOption.label,
                    category:
                      selectOption.category as TPartTagDetail['category'],
                  });
                }
              }
            }}
          />

          <div>
            <ButtonIcon
              hoverType="color"
              icon="IconClose"
              size="extra-small"
              tooltipMessage="Cancel"
              onClick={Cancel}
            />
          </div>
        </div>
      </>
    );
  };

  type TEditButton = {
    id: number;
  };
  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            {/*  <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit
            </Button>

            <Divider orientation="vertical" /> */}
            <Button
              type="link"
              allCaps={false}
              onClick={() => tagDelete({ id: props.id })}
            >
              Delete
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    setCurrentEdits((prevNumbers) => [...prevNumbers, 0]);
    setDynamicValue((prev) => [
      ...prev,
      { id: 0, tag_id: 0, tag: '', category: '', category_id: 0 },
    ]);
  };

  return (
    <>
      <div>
        {/*  {JSON.stringify(dynamicValue)} */}
        {tagCategories.map((item) => {
          const relatedTags = dynamicValue.filter(
            (tag) => tag.category === item.category,
          );
          if (relatedTags.length < 1) return;
          return (
            <div key={item.id} style={{ marginTop: 20 }}>
              {item.category && (
                <div style={{ padding: 10, backgroundColor: '#FAFAFA' }}>
                  <Typography tag="textsmall_strong" color="#666666">
                    {item.category.toUpperCase()}
                  </Typography>
                </div>
              )}

              <div
                style={{
                  padding: item.category ? 10 : undefined,
                  border: item.category ? 'solid 1px #FAFAFA' : undefined,
                }}
              >
                {relatedTags.map((tag) => (
                  <div key={tag.id}>
                    {currentEdits.includes(tag.id) ? (
                      <TagList
                        id={tag.id}
                        tag_id={tag.id}
                        setCurrentEdits={setCurrentEdits}
                        setDynamicValue={setDynamicValue}
                      />
                    ) : (
                      <div className={styles.editableContent}>
                        {tag.tag}
                        {editable && <EditButton id={tag.id} />}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      {editable && (
        <div key="TagAddMyadd" style={{ marginTop: 20 }}>
          {!currentEdits.includes(0) && (
            <Button
              type="primaryOutline"
              size="small"
              onClick={() => addNewRow()}
            >
              Add tag
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default PartDetailTags;
