import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  Divider,
  Drawer,
  LoadingSpinner,
  Stack,
  Tabs,
  Typography,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TPartTag, TPartTagArray } from '../Part/types';
import TagForm from './TagForm';
import { CategoryContext } from '@/contexts/TagCategories';

type TAdministration = 'tags' | 'users';

const Tags = () => {
  const { isLoading, data, makeApiCall } = useApiCall<TPartTagArray>();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState<TPartTag>();
  const [forceRefresh, setForceRefresh] = useState(Date.now);
  const { tagCategories } = useContext(CategoryContext);
  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url: import.meta.env.VITE_APP_API_URL + '/tags',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh]);

  const editTag = (tag: TPartTag) => {
    setIsOpen(true);
    setCurrentTag(tag);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentTag(undefined);
  };
  const handleUpdate = () => {
    setForceRefresh(Date.now);
  };

  if (isLoading)
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner size={32} color="#CCCCCC" />
      </div>
    );

  if (tagCategories && data)
    return (
      <>
        <Drawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fromRight={true}
          showCloseButton={true}
          width={500}
          isScrollable={true}
          closeOnClickOutside
          onClose={() => setCurrentTag(undefined)}
        >
          <TagForm
            currentTag={currentTag}
            onClose={handleClose}
            onUpdate={handleUpdate}
            tags={data.tags}
          />
        </Drawer>
        <div
          style={{
            textAlign: 'right',
            marginBottom: 30,
          }}
        >
          <Button
            size="small"
            type="primaryOutline"
            onClick={() => setIsOpen(true)}
          >
            Create new tag
          </Button>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(5,1fr)',
            gap: 30,
          }}
        >
          {tagCategories.map((item) => {
            const relatedTags = data.tags.filter(
              (tag) => tag.category_id === item.id && tag.category !== '',
            );

            return (
              <div key={item.id}>
                <Typography tag="textlarge_strong">{item.category}</Typography>
                {relatedTags.map((tag) => (
                  <div
                    key={tag.id}
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    <Typography tag="textsmall" color="#666666">
                      <div
                        className="tag-link"
                        onClick={() => editTag(tag)}
                        onKeyDown={() => editTag(tag)}
                        aria-hidden="true"
                        style={{ display: 'inline' }}
                      >
                        {tag.tag}
                      </div>
                      <Link to={'/parts?tags=' + tag.id}>
                        <div
                          className="tag-count"
                          style={{ display: 'inline-block' }}
                        >
                          {tag.count}
                        </div>
                      </Link>
                    </Typography>
                  </div>
                ))}
              </div>
            );
          })}
        </div>

        <Divider padding={50} />
        <div key="ObsoleteGroup">
          <Typography tag="textlarge_strong">
            Following tags for BOMs have been set obsolete:
          </Typography>
          {data.tags
            .filter(
              (tag) => !tag.category || tag.category?.toString().length < 1,
            )
            .map((obsoleteTag) => (
              <div
                key={obsoleteTag.id}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <Typography tag="textsmall" color="#666666">
                  <Stack direction="row" inline gap={2}>
                    <Button
                      type="link"
                      allCaps={false}
                      onClick={() => editTag(obsoleteTag)}
                    >
                      {obsoleteTag.tag}
                    </Button>
                    <sup>[{obsoleteTag.count}]</sup>
                  </Stack>
                </Typography>
              </div>
            ))}
        </div>
      </>
    );

  return null;
};

const Administration = () => {
  const navigate = useNavigate();
  const { status } = useParams();
  const [selectedOption, setSelectedOption] = useState<TAdministration>('tags');

  type TOption = {
    key: TAdministration;
    label: string;
  };

  const options: TOption[] = [
    { key: 'tags', label: 'Tags' },
    { key: 'users', label: 'Users' },
  ];

  const handleTabs = (value: TAdministration) => {
    let status: string = '/' + value.toLowerCase();

    if (value === 'tags') status = '';

    navigate('/administration' + status, { replace: true });

    setSelectedOption(value);
  };

  return (
    <div className="contentContainer">
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 30,
        }}
      >
        <div style={{ marginBottom: 40 }}>
          <Tabs
            centered={false}
            data={options}
            selectedKey={selectedOption}
            fontSize={14}
            onChange={handleTabs}
          />
        </div>
      </div>

      {status === 'users' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 300,
              width: 400,
              padding: 30,
              border: '1px solid #ededed',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              You can easily grant administrative privileges to specific users,
              enabling them to effectively manage and oversee BLISS content.
              <br />
              <br />
              <Button
                onClick={() =>
                  window.open(
                    'https://myaccount.microsoft.com/groups/groups-i-own',
                  )
                }
              >
                Manage Permissions
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <Tags />
        </div>
      )}
    </div>
  );
};

export default Administration;
