import { TSearchParameters } from '@/contexts/AreaContext/AreaProvider';
import { ChangeEvent } from 'react';

export function formatBomList(inputString: string): string {
  // Remove non-alphanumeric characters and extra spaces
  const cleanedString = inputString
    .replace(/,/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/\s+/g, ' ')
    .trim();

  // Replace single spaces with comma

  const formattedString = cleanedString.replace(/\s/g, ',');
  return formattedString;
}

export function commaSeparatedString(value: { key: string; label: string }[]) {
  return value.map((item) => item.key).join(',');
}

export const handleInputChange = (
  e: ChangeEvent<HTMLInputElement>,
  setForm: React.Dispatch<React.SetStateAction<TSearchParameters>>,
) => {
  const { id, value } = e.target;
  setForm((prevState) => ({
    ...prevState,
    [id]: value,
  }));
};
