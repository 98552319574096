import Approve from '@/pages/DataQuality/components/Approve';
import { TPartApprovalStatus } from '@/pages/Frame/types';
import { TPartList } from '@/pages/Part/types';
import {
  ExportDataButton,
  Table,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderAlignType,
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { Link } from 'react-router-dom';
import styles from './SearchResultsTable.module.scss';

type TSearchResultsTable = {
  parts: TPartList[];
  numberRows?: number;
  status?: TPartApprovalStatus;
};

const processString = (frameFamily: string, input: string) => {
  if (!input) return '';
  const parts = input.split(' ');
  const processedParts = parts.map((part, index) => {
    const hasHtmlTag = /<\/?[a-z][\s\S]*>/i.test(part);
    if (hasHtmlTag) {
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    } else {
      return (
        <Link key={index} to={`/frames/${frameFamily}/${frameFamily}${part}`}>
          <div className={styles.application}>{part.replace(/[()]/g, '')}</div>
        </Link>
      );
    }
  });
  return processedParts;
};

const returnContent = (
  parts: TPartList[],
  status: TPartApprovalStatus = 'APPROVED',
) => {
  let headers: TableHeaderType[] = [];
  let rows: TableRowData[] = [];
  let rowsExport: TableRowData[] = [];
  if (status === 'PENDING' || status === 'POSTPONED' || status === 'REJECTED') {
    headers = [
      {
        align: 'left',
        title: 'Action Type',
      },
      {
        align: 'left',
        title: 'Proposed by',
      },
      {
        align: 'left',
        title: 'Proposed at',
        columnWidth: '150px',
      },
      {
        align: 'left',
        title: 'BOM',
      },

      {
        align: 'left',
        title: 'Part Type',
      },
      {
        align: 'left',
        title: 'Frame Family',
      },
      {
        align: 'left',
        title: 'Application',
        columnWidth: '100px',
      },
      {
        align: 'left',
        title: 'Owner(s)',
      },
      {
        align: 'left',
        title: 'SAP Status',
      },

      {
        align: 'left',
        title: 'Base Material',
      },
      {
        align: 'left',
        title: 'Internal Coating',
      },
      {
        align: 'left',
        title: 'External Coating',
      },
      {
        align: 'left',
        title: 'Thermal Barrier',
      },
      ...(status !== 'REJECTED'
        ? [{ align: 'right' as TableHeaderAlignType, title: '' }]
        : []),
    ];

    rows = parts.map((item: TPartList) => {
      return {
        data: {
          action_type: item.action_type,
          source: item.source,
          created_at: item.created_at,
          bom: item.bom,
          part_type: item.part_type,
          frame_family: (
            <Link
              to={`/frames/${item.frame_family}`}
              style={{ color: '#000000' }}
            >
              {item.frame_family}
            </Link>
          ),
          application: item.application,
          part_owner: item.part_owners.join(', '),
          sap_status: item.sap_status,
          base_material: item.base_material,
          internal_coating: item.internal_coating,
          external_coating: item.external_coating,
          thermal_barrier: item.thermal_barrier,
          edit: <Approve status={status} bom={item.bom} id={item.id} />,
          /* ...(status !== 'REJECTED' && {
            edit: <Approve status={status} bom={item.bom} id={item.id} />,
          }), */
        },
        id: item.bom,
      };
    });
    rowsExport = parts.map((item: TPartList) => {
      return {
        data: {
          action_type: item.action_type,
          source: item.source,
          created_at: item.created_at,
          bom: item.bom,
          part_type: item.part_type,
          frame_family: item.frame_family,
          part_owner: item.part_owners.join(', '),
          sap_status: item.sap_status,
          base_material: item.base_material,
          internal_coating: item.internal_coating,
          external_coating: item.external_coating,
          thermal_barrier: item.thermal_barrier,
          edit: status,
          /* ...(status !== 'REJECTED' && {
            edit: <Approve status={status} bom={item.bom} id={item.id} />,
          }), */
        },
        id: item.bom,
      };
    });
  } else {
    //default
    headers = [
      {
        align: 'left',
        title: 'BOM',
      },
      {
        align: 'left',
        title: 'Sub Styles',
      },
      {
        align: 'left',
        title: 'Part Type',
      },
      {
        align: 'left',
        title: 'Frame Family',
      },
      {
        align: 'left',
        title: 'Application',
        columnWidth: '100px',
      },
      {
        align: 'left',
        title: 'Owner(s)',
      },

      {
        align: 'left',
        title: 'PDP Status',
      },
      {
        align: 'left',
        title: 'SAP Status',
      },
      {
        align: 'left',
        title: 'Assembly Cluster',
      },
      {
        align: 'left',
        title: 'Life Cluster',
      },
      {
        align: 'left',
        title: 'Engineering Status',
      },
      {
        align: 'left',
        title: 'Base Material',
      },
      {
        align: 'left',
        title: 'Internal Coating',
      },
      {
        align: 'left',
        title: 'External Coating',
      },
      {
        align: 'left',
        title: 'Thermal Barrier',
      },
    ];

    rows = parts.map((item: TPartList) => {
      return {
        data: {
          bom: <Link to={`/parts/detail/${item.bom}`}>{item.bom}</Link>,
          sub_styles: item.sub_styles,
          part_type: item.part_type,
          frame_family: (
            <Link
              to={`/frames/${item.frame_family}`}
              style={{ color: '#000000' }}
            >
              {item.frame_family}
            </Link>
          ),
          application: processString(item.frame_family, item.application),

          part_owner: item.part_owners.join(', '),

          pdp_status: item.pdp_status,
          sap_status: item.sap_status,
          assembly_cluster: item.assembly_cluster,
          life_cluster: item.life_cluster,
          engineering_status: item.engineering_status,
          base_material: item.base_material,
          internal_coating: item.internal_coating,
          external_coating: item.external_coating,
          thermal_barrier: item.thermal_barrier,
        },
        id: item.bom,
      };
    });
    rowsExport = parts.map((item: TPartList) => {
      return {
        data: {
          bom: item.bom,
          sub_styles: item.sub_styles,
          part_type: item.part_type,
          frame_family: item.frame_family,
          application: item.application,

          part_owner: item.part_owners.join(', '),

          pdp_status: item.pdp_status,
          sap_status: item.sap_status,
          assembly_cluster: item.assembly_cluster,
          life_cluster: item.life_cluster,
          engineering_status: item.engineering_status,
          base_material: item.base_material,
          internal_coating: item.internal_coating,
          external_coating: item.external_coating,
          thermal_barrier: item.thermal_barrier,
        },
        id: item.bom,
      };
    });
  }

  return { headers, rows, rowsExport };
};

const SearchResultsTable = ({
  parts,
  status = 'APPROVED',
}: TSearchResultsTable) => {
  const { headers, rows, rowsExport } = returnContent(parts, status);

  /*   const handleRowClick = (bom: string) => {
    let st = '?status=';
    if (status === 'REJECTED' || status === 'APPROVED') return;

    st += status;

    navigate('/parts/detail/' + bom + st);
  }; */

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <ExportDataButton
          data={rowsExport.map((item) => {
            return item.data;
          })}
          title={'Export'}
          fileName={'parts'}
          type="primaryOutline"
          size="small"
          inline
        />
      </div>

      <Table
        headerVerticalAlign="bottom"
        headers={headers}
        rows={rows}
        typographyTag="textsmall"
        stickyHeader
        indentation={0}
        /*  pointerCursor={status === 'PENDING' || status === 'POSTPONED'} */
        /*  onRowClick={(value) =>
          (status === 'PENDING' || status === 'POSTPONED') &&
          handleRowClick(String(value.data.bom))
        } */
      />
    </>
  );
};

export default SearchResultsTable;
