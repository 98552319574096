import { AreaContext } from '@/contexts/AreaContext/AreaContext';
import { TSearchParameters } from '@/contexts/AreaContext/AreaProvider';
import { initialSearchParameters } from '@/contexts/AreaContext/defaultObjects';
import {
  Button,
  Icon,
  Input,
  Stack,
} from '@data-products-and-ai/react-components';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBom } from '../SearchBom';
import { SearchItem } from './SearchItem';
import SearchTags from './SearchItem/SearchTags';
import { formatBomList, handleInputChange } from './helpers';

type TFormSearch = {
  searchType: 'data_approval' | 'parts';
  filters?: TSearchParameters | null;
  filtersEnabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch: (params: any) => void; // Function to handle search
};

const PartSearch = (props: TFormSearch) => {
  const {
    searchArea,
    searchParameters,

    setSearchParameters,
  } = useContext(AreaContext);
  const navigate = useNavigate();
  const location = useLocation();
  const locationQuerystring = useLocation().search;

  useEffect(() => {
    if (props.filters && props.filtersEnabled) {
      if (!locationQuerystring) {
        if (props.searchType === 'data_approval') {
          const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
            return Object.keys(obj) as (keyof Obj)[];
          };

          type TNotSap = keyof TSearchParameters;

          const notSapKeys: TNotSap[] = [
            'application',
            'pdp_status',
            'engineering_status',
            'top_coat',
            'earmark',
            'casting_vendor',
            'source',
            'assembly_cluster',
            'life_cluster',
            'design_feature',
          ];
          const updatedParameters = { ...props.filters };

          if (props.searchType === 'data_approval') {
            objectKeys(props.filters).forEach((key) => {
              if (notSapKeys.includes(key)) {
                updatedParameters[key] = '';
              }
            });
          }
          setSearchParameters(updatedParameters);
        } else {
          setSearchParameters(props.filters);
        }
      }
      //;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters, props.filtersEnabled]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let filteredForm: Partial<TSearchParameters> = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(searchParameters).filter(([_, value]) => value !== ''),
    );

    if (filteredForm.bom_list) {
      const { bom_list } = filteredForm; // Destructure 'bom_list' from the filteredForm
      filteredForm = { bom_list };
    } else {
      delete filteredForm.bom_list;
    }

    if (filteredForm.bom_list)
      filteredForm.bom_list = formatBomList(filteredForm.bom_list);
    const params = new URLSearchParams(filteredForm).toString();

    navigate(
      location.pathname + '?' + params + '&ts=' + Math.floor(Date.now() / 1000),
      {
        replace: true,
      },
    );
  };

  useEffect(() => {
    const locationParams = new URLSearchParams(locationQuerystring);
    const queryParams = Object.fromEntries(locationParams.entries());

    const updatedState = { ...initialSearchParameters, ...queryParams };
    setSearchParameters(updatedState);

    let encoded = Object.keys(queryParams)
      .map((key) => {
        if (encodeURIComponent(key) === 'bom_list') {
          //in case it's a bom search we just need to put the boms divided by comma
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
          );
        } else {
          //if it's a "normal" search we need to split the string and add a parameter per search item
          if (queryParams[key].includes(',')) {
            const values = queryParams[key].split(',');
            return values
              .map(
                (value) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
              )
              .join('&');
          } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              queryParams[key],
            )}`;
          }
        }
      })
      .join('&');

    if (!encoded) encoded = '?';

    // setSearchParams(encoded);
    props.onSearch(encoded);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationQuerystring]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        {searchArea === 'bom' ? (
          <SearchBom />
        ) : (
          <div>
            {/*  {JSON.stringify(searchParameters)} */}
            <div
              style={{
                backgroundColor: '#fafafa',
                padding: 20,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 20,
                }}
              >
                <Input
                  defaultValue={searchParameters.text}
                  id="text"
                  placeholder="Search for any stored value, not only BOM #"
                  onChange={(e) => handleInputChange(e, setSearchParameters)}
                />

                <Input
                  defaultValue={searchParameters.bom_list}
                  id="bom_list"
                  placeholder="Search for LIST of BOM#s"
                  onChange={(e) => handleInputChange(e, setSearchParameters)}
                />
              </div>
            </div>

            <div
              className="noselect"
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  props.searchType === 'data_approval' ? '5' : '6'
                }, 1fr)`,
                width: '100%',
                gap: 20,
                /*       opacity: props.filtersEnabled ? 0.2 : 1,
                pointerEvents: props.filtersEnabled ? 'none' : 'auto', */
              }}
            >
              <SearchItem key="part_types" itemKey="part_type" />
              <SearchItem key="frame_family" itemKey="frame_family" />

              <SearchItem key="application" itemKey="application" />

              <SearchItem key="part_owner" itemKey="part_owners" />

              {props.searchType === 'parts' && (
                <SearchItem key="pdp_status" itemKey="pdp_status" />
              )}
              <SearchItem key="sap_status" itemKey="sap_status" />
              {props.searchType === 'parts' && (
                <SearchItem key="assembly_cluster" itemKey="assembly_cluster" />
              )}
              {props.searchType === 'parts' && (
                <SearchItem key="life_cluster" itemKey="life_cluster" />
              )}

              {props.searchType === 'parts' && (
                <SearchItem
                  key="engineering_status"
                  itemKey="engineering_status"
                />
              )}
              <SearchItem key="base_material" itemKey="base_material" />
              <SearchItem key="internal_coating" itemKey="internal_coating" />
              <SearchItem key="external_coating" itemKey="external_coating" />
              <SearchItem key="thermal_barrier" itemKey="thermal_barrier" />
              {props.searchType === 'parts' && (
                <SearchTags key="tags" itemKey="tags" />
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: 'center', paddingTop: 30 }}>
        <Button buttonType="submit">
          <Stack direction="row" gap={5} horizontalAlign="center">
            <Icon icon="IconSearch" color="#FFFFFF" iconSize={0.8} />
            <> Search</>
          </Stack>
        </Button>
      </div>
    </form>
  );
};

export default PartSearch;
