import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  Divider,
  Input,
  Label,
  LoadingSpinner,
  Select,
  SelectOption,
  Stack,
} from '@data-products-and-ai/react-components';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { TPartTag } from '../Part/types';
import { CategoryContext } from '@/contexts/TagCategories';

type TTagForm = {
  currentTag?: TPartTag;
  onClose: () => void;
  onUpdate: () => void;
  tags: TPartTag[];
};

const TagForm = ({ currentTag, tags, onClose, onUpdate }: TTagForm) => {
  const { makeApiCall } = useApiCall<TPartTag>();
  const [loading, setLoading] = useState(false);
  const [tagForm, setTagForm] = useState<Partial<TPartTag>>();
  const { tagCategories } = useContext(CategoryContext);
  useEffect(() => {
    setTagForm({
      id: currentTag?.id,
      tag: currentTag?.tag,
      category_id: currentTag?.category_id,
      prev_tag_id: currentTag?.prev_tag_id,
    });
  }, [currentTag]);

  type TUpdate = {
    method: 'POST' | 'PUT' | 'DELETE';
    data: Partial<TPartTag>;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setTagForm((prevState) => ({
      ...prevState,
      // If the changed field is 'category', set 'prev_tag_id' to null
      ...(e.target.name === 'category_id' ? { prev_tag_id: null } : {}),
      // Update the value of the field that triggered the change
      [e.target.name]:
        e.target.name === 'prev_tag_id' && e.target.value === ''
          ? null
          : e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!tagForm?.category_id) return;

    if (tagForm) Update({ data: tagForm, method: currentTag ? 'PUT' : 'POST' });
  };
  const handleDelete = () => {
    const result = confirm('Are you sure you want to make this tag obsolete?');
    if (result) {
      if (tagForm) Update({ data: tagForm, method: 'DELETE' });
    }
  };

  const Update = ({ data, method }: TUpdate) => {
    const update = async () => {
      let payload: Partial<TPartTag> = {
        id: data.id,
      };

      if (method === 'PUT') {
        payload = {
          id: data.id,
          tag: data.tag,
          category_id: data.category_id,
          prev_tag_id: data.prev_tag_id,
        };
      } else if (method === 'POST') {
        payload = {
          tag: data.tag,
          category_id: data.category_id,
          prev_tag_id: data.prev_tag_id,
        };
      }

      setLoading(true);
      try {
        return await makeApiCall({
          method: method,
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/tags`,
          payload: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        setLoading(false);

        throw error;
      }
    };

    /* handleClose(); */

    update()
      .then((r) => {
        if (r.status && r.status !== 200) {
          r.json()
            .then((/* j: { detail: string } */) => {
              alert('Something went wrong');
              setLoading(false);
            })
            .catch(() => {
              alert('Something went wrong');
              setLoading(false);
            });

          return;
        }
        onUpdate();
        onClose();

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch(() => {
        alert('Something went wrong');
        setLoading(false);
      });
  };

  if (loading)
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingSpinner size={32} color="#CCCCCC" />
      </div>
    );

  return (
    <div style={{ padding: 30 }}>
      {/*  {JSON.stringify(tagForm)} */}
      <form onSubmit={handleSubmit}>
        <div>
          {currentTag !== undefined ? (
            <>
              This tag is used in <b>{currentTag.count}</b> BOMs
            </>
          ) : (
            <>Create a new tag:</>
          )}
        </div>

        {/*  <Input id="id" defaultValue={currentTag?.id} />
          <Input
            id="prev_tag_id"
            defaultValue={currentTag?.prev_tag_id?.toString()}
          /> */}

        <Label marginTop={30}>Tag</Label>
        <Input
          /* key={'tag_' + Date.now()} */
          id="tag"
          defaultValue={tagForm && tagForm.tag}
          placeholder="Type a tag name"
          onChange={(e) => handleChange(e)}
        />
        <Label marginTop={30}>Category</Label>
        <Select
          /* key={'category_' + Date.now()} */
          id="category_id"
          defaultValue={
            tagForm && (tagForm.category_id ? String(tagForm.category_id) : '')
          }
          placeholder="Choose a tag category"
          onChange={(e) => handleChange(e)}
        >
          {tagCategories.map((item) => {
            return (
              <SelectOption
                key={item.id}
                value={item.id.toString()}
                label={item.category}
              />
            );
          })}
        </Select>
        <Label marginTop={30}>Previous Tag</Label>
        <Select
          /* key={'category_' + Date.now()} */
          id="prev_tag_id"
          defaultValue={
            tagForm &&
            (tagForm.prev_tag_id ? tagForm.prev_tag_id.toString() : '')
          }
          placeholder="None (place at the beginning)"
          selectablePlaceholder
          onChange={(e) => handleChange(e)}
        >
          {tags
            .filter(
              (tag) =>
                Number(tag.category_id) === Number(tagForm?.category_id) &&
                Number(tag.id) !== Number(tagForm?.id),
            )
            .map((tag) => {
              return (
                <SelectOption
                  key={tag.id}
                  value={tag.id.toString()}
                  label={tag.tag}
                />
              );
            })}
        </Select>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <div style={{ display: 'inline-block' }}>
            <Stack direction="row" gap={10}>
              <Button type="primaryOutline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                buttonType="submit"
                disabled={
                  !tagForm?.category_id ||
                  !tagForm?.tag ||
                  tagForm?.tag.trim() === ''
                }
              >
                Confirm
              </Button>
            </Stack>
          </div>
        </div>
        {currentTag !== undefined && currentTag.category !== '' && (
          <>
            <Divider padding={50} />
            <div style={{ textAlign: 'center' }}>
              <Button type="link" onClick={handleDelete}>
                Make tag obsolete
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default TagForm;
